// React libs
import React, { FC, useState } from 'react'
import { useTranslation } from 'react-i18next';
// Components
import AppLayout from '../../../App/Components/Layout/AppLayout/AppLayout'
import PoisList from '../../../Core/Components/Form/PoisList/PoisList';
// Hooks
import usePois from '../../../Core/Data/Hooks/Pois';
// Types
import * as Types from './Resources.type'

const Resources: FC<Types.IProps> = () => {
  // State
  const [filters, updateFilters] = useState<any>({
    isProject: false,
  })
  // Variables
  const { t } = useTranslation(['resources']);
  const resources = usePois({
    filter: filters,
    expand: [
      'person',
      'poiStyle',
      'poiType',
      'territory',
      'thematic',
      'valueSlot',
    ]
  })

  return <AppLayout
    headerConf={{
      title: {
        label: t('resources:title'),
        icon: 'map-marker'
      }
    }}
    isLoading={resources.isLoading}
    loadingMessages={[t('resources:loadingMessage')]}
    className='h-full w-full p-2 overflow-auto'
  >
    <PoisList pois={resources.data ?? []} updatePoiFilters={updateFilters} refreshPois={resources.refresh} />
  </AppLayout>
}

export default Resources
