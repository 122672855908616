// React libs
import React, { FC, useState, useEffect } from 'react';
import { Field, Form, FormikProps, Formik } from 'formik';
import { useTranslation } from 'react-i18next';
// Components
import FormHeader from '../../../../Core/Components/Form/FormHeader/FormHeader'
import FormTitle from '../../../../Core/Components/Form/FormTitle/FormTitle';
import Grid from '@material-ui/core/Grid';
import * as FormFields from '../../../../Core/Components/Form/FormFields/FormFields';
// Type
import * as Types from './FormProjectIdentity.type';
// Utils
import {
  getFormPropTypes, isDisabled,
} from '../../../../Core/Utils/FormUtils';
import Autocomplete from '../../../../Core/Components/UiKit/Form/Autocomplete/Autocomplete';
import CommonService from '../../../../Core/Data/Services/CommonService';
import Box from '@material-ui/core/Box';
// Utils
import useServiceErrorHandler from '../../../../Core/Utils/useServiceErrorHandler';

const FormProjectIdentity: FC<Types.IProps> = ({
  defaultValues,
  miscData,
  miscFunctions,
  onFormSubmit,
  validationSchema,
}) => (
  <Formik
    initialValues={defaultValues}
    onSubmit={onFormSubmit}
    validationSchema={validationSchema}
    enableReinitialize
    validateOnBlur={false}
  >
    {(formikProps: FormikProps<any>) => (
      <ProjectIdentityFormikForm
        {...formikProps}
        miscFunctions={miscFunctions}
        miscData={miscData}
      />
    )}
  </Formik>
);

FormProjectIdentity.propTypes = getFormPropTypes();
export default FormProjectIdentity;

const ProjectIdentityFormikForm = ({
  errors,
  isSubmitting,
  touched,
  miscData,
  miscFunctions,
  values
}: FormikProps<any> & any) => {
  // State
  const [phasePois, setPhasePois] = useState<any>([])
  const [allowedPersons, setAllowedPersons] = useState<any>([])

  // Variables
  const { t } = useTranslation(['common', 'project']);
  const handleServiceError = useServiceErrorHandler()
  const fieldContainerClasses = 'flex w-full items-center';
  const fieldContainerFieldClasses = `${fieldContainerClasses} flex-1 mb-5`;
  const isEditionMode = miscData.isEditionMode ?? false

  // Effects
  useEffect(() => {
    Promise.all([
      CommonService.getPhase().then(value => {
        setPhasePois(value.data.map((phase: any) => ({
          label: phase.label,
          value: phase.id,
        })
        ))
      }),
      CommonService.getPersonsWithAccount().then(value => {
        setAllowedPersons(
          value.data.filter((person: any) => ['ADMIN', 'WRITER'].includes(person.account.accessType.id))
            .map((person: any) => ({
              label: `${person.name} ${person.firstname}`,
              value: person.id
            }))
        )
      }),
    ]).catch(handleServiceError)
  }, [handleServiceError]);

  return (
    <>
      <div className='h-0.7/10 w-full pl-2 pt-1'>
        <FormHeader
          isEditionMode={isEditionMode}
          toggleEditionMode={miscFunctions?.toggleEditionMode}
          title={t('project:identity')}
        />
      </div>
      <Form data-testid='form-project-identity' className='h-9.3/10 w-full p-1'>
        <div className='h-9.3/10 w-full overflow-auto'>
          <Grid container spacing={1} className='w-full'>
            <Grid item xs={6}>
              <FormFields.FormPoiType
                name='type'
                type='project'
                disabled={!isEditionMode}
                onPoiTypeChange={miscFunctions?.onPoiTypeChange}
              />
              <FormFields.FormName
                name='name'
                disabled={!isEditionMode}
              />
              <FormFields.FormThematic
                name='thematics'
                disabled={!isEditionMode}
              />
            </Grid>
            <Grid item xs={6} className='flex justify-center'>
              <FormFields.FormImage
                name='image'
                disabled={!isEditionMode}
                images={miscData?.linkedImageUrls}
              />
            </Grid>
            <Grid item xs={12}>
              <FormTitle title={t('project:projectCurrentState')} />
              <div className={fieldContainerFieldClasses}>
                <Field
                  id='currentPhaseType'
                  name='currentPhaseType'
                  component={Autocomplete}
                  options={phasePois}
                  label={t('project:currentState')}
                  color='secondary'
                  className='flex-1'
                  disabled={!isEditionMode}
                />
              </div>
            </Grid>
            <Grid item xs={12}>
              <FormTitle title={t('project:localization')} />
            </Grid>
            <Grid item xs={6}>
              <FormFields.FormTerritory
                name='territory'
                disabled={!isEditionMode}
              />
              <FormFields.FormGeo
                name='geo'
                disabled={!isEditionMode}
              />
            </Grid>
            <Grid item xs={6}>
              <FormFields.FormAddress
                name='address'
                disabled={!isEditionMode}
                selectedAddressId={values.address}
              />
            </Grid>
            <Grid item xs={12}>
              <FormTitle title={t('project:description')} />
              <FormFields.FormComment
                name='comment'
                disabled={!isEditionMode}
              />
              <FormFields.FormKeywords
                name='keywords'
                disabled={!isEditionMode}
              />
            </Grid>
            <Grid item xs={12}>
              <FormTitle title={t('project:management')} />
              <div className={fieldContainerFieldClasses}>
                <Field
                  id='allowedPersons'
                  name='allowedPersons'
                  component={Autocomplete}
                  options={allowedPersons}
                  label={t('project:contributors')}
                  color='secondary'
                  className='flex-1'
                  multiple
                  disabled={!isEditionMode}
                />
              </div>
            </Grid>
          </Grid>
        </div>
        <div className='h-0.7/10 w-full'>
          {isEditionMode && <FormFields.FormSubmitButtons
            isSubmitting={isSubmitting}
            disabled={isDisabled(errors, isSubmitting, touched)}
            onCancel={miscFunctions?.toggleEditionMode}
          />}
        </div>
      </Form>
    </>
  );
};
