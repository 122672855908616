// React libs
import React, { FC, useState } from 'react'
import { useTranslation } from 'react-i18next';
// Components
import AppLayout from '../../../App/Components/Layout/AppLayout/AppLayout'
import PoisList from '../../../Core/Components/Form/PoisList/PoisList';
// Hooks
import usePois from '../../../Core/Data/Hooks/Pois';
// Types
import * as Types from './Projects.type'

const Projects: FC<Types.IProps> = () => {
  // State
  const [filters, updateFilters] = useState<any>({
    isProject: true
  })
  // Variables
  const { t } = useTranslation(['projects']);
  const projects = usePois({ filter: filters })

  return <AppLayout
    headerConf={{
      title: {
        label: t('projects:title'),
        icon: 'cubes'
      }
    }}
    isLoading={projects.isLoading}
    loadingMessages={[t('projects:loadingMessage')]}
    className='h-full w-full p-2 overflow-auto'
  >
    <PoisList pois={projects.data ?? []} refreshPois={projects.refresh} updatePoiFilters={updateFilters} isProject />
  </AppLayout>
}

export default Projects