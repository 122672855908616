// React libs
import Collapse from '@material-ui/core/Collapse';
import React, { FC, useContext, useEffect, useCallback } from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import TreeItem, { TreeItemProps } from '@material-ui/lab/TreeItem';
import TreeView from '@material-ui/lab/TreeView';
import { sortBy } from 'lodash'
import { useTranslation } from 'react-i18next';
// Components
import AdvancedFilters from './AdvancedFilters/AdvancedFilters'
import Checkbox from '../../../../../../Core/Components/UiKit/Form/Checkbox/Checkbox';
import EcosystemButton from '../../../../../../Core/Components/UiKit/Button/EcosystemButton/EcosystemButton';
import FavoriteButton from '../../../../../../Core/Components/UiKit/Button/FavoriteButton/FavoriteButton';
import FavoriteFilters from './FavoriteFilters/FavoriteFilters';
// Contexts
import MapFiltersContext from '../../../../Data/Contexts/MapFiltersContext';
import PoiTypeContext from '../../../../Data/Contexts/PoiTypesContext';
import LinkTypeContext from '../../../../Data/Contexts/LinkTypesContext';
import PhaseTypeContext from '../../../../Data/Contexts/PhaseTypeContext';
// Type
import * as Types from './MapSidebarFilter.type';
import * as MapTypes from '../../../../Data/Models/Map.type';
import FaIcon from '../../../../../../Core/Components/UiKit/Icon/FaIcon/FaIcon';

const MapSidebarFilter: FC<Types.IProps> = () => {
  // Types
  type TMarker = 'links' | 'projects' | 'resources';

  // Variables
  const { t } = useTranslation(['map']);

  // Contexts
  const { areAdvancedFiltersSelected, areFavoriteFiltersSelected, mapFilters, updateAreFavoriteFiltersSelected, updateMapFilters } = useContext(MapFiltersContext);
  const { poiTypes } = useContext(PoiTypeContext);
  const { linkTypes } = useContext(LinkTypeContext);
  const { phaseTypes } = useContext(PhaseTypeContext);

  // Effects
  useEffect(() => {
    const newFilters = { ...mapFilters };
    if (!newFilters.initialized) {
      // --> linkTypes
      linkTypes.forEach((t: MapTypes.ILinkType) => {
        const label = t.id;
        if (!newFilters.markers.links.hasOwnProperty(label)) {
          newFilters.markers.links[label] = false;
        }
      });
      // --> phaseTypes
      phaseTypes.forEach((t: MapTypes.IPhaseType) => {
        const label = t.id;
        if (!newFilters.markers.projects.hasOwnProperty(label)) {
          newFilters.markers.projects[label] = false;
        }
      });
      // --> poiTypes
      poiTypes
        .filter((t: MapTypes.IPoiType) => !t.isProject)
        .forEach((t: MapTypes.IPoiType) => {
          const label = t.id;
          if (!newFilters.markers.resources.hasOwnProperty(label)) {
            newFilters.markers.resources[label] = false;
          }
        });
      newFilters.initialized = true;
      updateMapFilters(newFilters);
    }
  }, [linkTypes, phaseTypes, mapFilters, poiTypes, updateMapFilters]);

  // Handlers
  const updateFilters = useCallback((
    field: string,
    value: boolean,
    id: string = '',
    path?: TMarker | 'none' | undefined
  ) => {
    const newFilters: any = { ...mapFilters };
    if (!path) {
      newFilters[field as keyof MapTypes.IMapFilters] = value as any;
    } else {
      if (path === 'none') {
        // Set all true/false
        Object.keys(newFilters.markers).forEach((key: string) => {
          if (key === field) {
            Object.keys(newFilters.markers[key as TMarker]).forEach(
              (k: string) => {
                newFilters.markers[key as TMarker][k] = value;
              }
            );
          }
        });
      } else {
        newFilters.markers[path as TMarker][
          field as keyof MapTypes.IMapFilters
        ] = value as any;
      }
    }
    newFilters.needMapRefresh = true;
    updateMapFilters(newFilters);
  }, [mapFilters, updateMapFilters])
  const toggleLinksFilter = useCallback(() => updateFilters('links', !mapFilters.links), [mapFilters, updateFilters])
  const toggleFavoritesView = useCallback(() => updateAreFavoriteFiltersSelected(!areFavoriteFiltersSelected), [areFavoriteFiltersSelected, updateAreFavoriteFiltersSelected])


  // Getters
  const getTreeLabelComponent = (
    root: TMarker | 'none',
    name: string,
    label: string,
    element?: MapTypes.IPoiType | MapTypes.IPhaseType | MapTypes.ILinkType
  ) => {
    let icon = '';
    let iconColor = '#000000';
    if (element) {
      icon = element.style.data.icon;
      if (element.style.data.hasOwnProperty('markerColor')) {
        iconColor = (element as MapTypes.IPoiType | MapTypes.IPhaseType).style
          .data.markerColor;
      } else if (element.style.data.hasOwnProperty('color')) {
        iconColor = (element as MapTypes.ILinkType).style.data.color;
      }
    }

    let value = false;
    if (root === 'none') {
      // Set all true/false
      Object.keys(mapFilters.markers).forEach((key: string) => {
        if (key === name) {
          Object.keys(mapFilters.markers[key as TMarker]).forEach(
            (k: string) => {
              value = value || mapFilters.markers[key as TMarker][k];
            }
          );
        }
      });
    } else {
      value = mapFilters.markers[root][name];
    }

    return (
      <Checkbox
        label={
          <div className='flex items-center'>
            {root !== 'none' && (
              <i
                className={`bg-white-80 fa fa-${icon} flex h-6 items-center justify-center mr-1 rounded-full text-xs w-6`}
                style={{ color: iconColor }}
              ></i>
            )}
            <div
              className={`${root === 'none' ? 'font-extrabold text-xl' : ''}`}
            >
              {label}
            </div>
          </div>
        }
        color='secondary'
        field={{ name, value }}
        labelControlled={root !== 'none'}
        form={{
          setFieldValue: (field: string, newValue: boolean) =>
            updateFilters(field, newValue, element?.id || '', root),
        }}
        onClick={root === 'none' ? (e: any) => e.stopPropagation() : undefined}
      />
    );
  };

  // Subcomponents
  const StyledTreeItem = (props: TreeItemProps) => {
    return <TreeItem {...props} TransitionComponent={Collapse} />;
  };

  return (
    <div
      className='h-9.7/10 p-0 sm:p-2 text-white'
      data-testid='map-sidebar-filter'
    >
      <div className='flex justify-between mb-4 h-0.5/10'>
        <div>
          <EcosystemButton
            tooltip={t(`map:sidebar:filters.ecosystems.${mapFilters.links ? 'hideEcosystems' : 'displayEcosystems'}`) ?? ''}
            onClick={toggleLinksFilter}
            isActive={mapFilters.links}
          />
        </div>
        <div>
          <FavoriteButton
            tooltip={t('map:sidebar:filters.favoriteFilter.title')}
            value={areFavoriteFiltersSelected}
            onClick={toggleFavoritesView}
          />
        </div>
      </div >
      <div className='h-9.5/10 overflow-auto'>
        {areFavoriteFiltersSelected
          ? <FavoriteFilters />
          : areAdvancedFiltersSelected
            ? <AdvancedFilters />
            : <div className='flex flex-col h-full'>
              <TreeView
                defaultExpanded={['1', '2', '3']}
                defaultCollapseIcon={
                  <FaIcon name='caret-down' className='text-3xl' />
                }
                defaultExpandIcon={<FaIcon name='caret-right' className='text-3xl' />}
              >
                <StyledTreeItem
                  nodeId='1'
                  label={getTreeLabelComponent(
                    'none',
                    'resources',
                    t('map:sidebar.filters.markers.resources.label')
                  )}
                >
                  {sortBy(poiTypes.filter((t: MapTypes.IPoiType) => !t.isProject), poi => poi.label.toLowerCase())
                    .map((t: MapTypes.IPoiType, index: number) => (
                      <StyledTreeItem
                        nodeId={(100 + index).toString()}
                        label={getTreeLabelComponent('resources', t.id, t.label, t)}
                        key={t.id}
                      />
                    ))}
                </StyledTreeItem>
                <StyledTreeItem
                  nodeId='2'
                  label={getTreeLabelComponent(
                    'none',
                    'projects',
                    t('map:sidebar.filters.markers.projects.label')
                  )}
                >
                  {phaseTypes
                    .sort((a: MapTypes.IPhaseType, b: MapTypes.IPhaseType) =>
                      a.order < b.order ? -1 : a.order > b.order ? 1 : 0
                    )
                    .map((t: MapTypes.IPhaseType, index: number) => (
                      <StyledTreeItem
                        nodeId={(200 + index).toString()}
                        label={getTreeLabelComponent('projects', t.id, t.label, t)}
                        key={t.id}
                      />
                    ))}
                </StyledTreeItem>
                <StyledTreeItem
                  nodeId='3'
                  label={getTreeLabelComponent(
                    'none',
                    'links',
                    t('map:sidebar.filters.markers.links.label')
                  )}
                >
                  {linkTypes.map((t: MapTypes.ILinkType, index: number) => (
                    <StyledTreeItem
                      nodeId={(300 + index).toString()}
                      label={getTreeLabelComponent('links', t.id, t.label, t)}
                      key={t.id}
                    />
                  ))}
                </StyledTreeItem>
              </TreeView>
            </div>}
      </div>
    </div >
  );
};

MapSidebarFilter.propTypes = {};

export default MapSidebarFilter;
