// React libs
import { useEffect, useMemo } from 'react';
// Services
import CommonService from '../Services/CommonService';
// Types
import * as CoreTypes from '../Models/Core.type';
// Hooks
import useGenericData, { IGetGenericData } from './GenericData';
// Utils
import { ifDef } from '../../Utils/Misc';

export interface IGetPoisHook extends IGetGenericData<CoreTypes.IPoi> {
}

const usePois = (params?: CoreTypes.IUrlQuery): IGetPoisHook => {
  // Variables
  const stringParams = useMemo(() => ifDef(params, JSON.stringify), [params])
  const data = useGenericData(() => CommonService.getPois(params))
  // Effects
  useEffect(() => {
    data.refresh()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stringParams])
  return data
}

export default usePois