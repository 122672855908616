// React libs
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
// Components
import FormHeader from '../../../../Core/Components/Form/FormHeader/FormHeader';
import LocalLoader from '../../../../Core/Components/UiKit/Loader/LocalLoader/LocalLoader';
import PoiEcosystem from '../../../../Core/Components/Form/PoiEcosystem/PoiEcosystem';
// Types
import * as Types from './ResourceEcosystem.type'
// Hooks
import usePoiLinks from '../../../../Core/Data/Hooks/PoiLinks';
import usePois from '../../../../Core/Data/Hooks/Pois';

const ResourceEcosystem: FC<Types.IProps> = ({ poi }) => {
  // Variables
  const { t } = useTranslation(['resource', 'common']);
  const links = usePoiLinks({
    expand: ['linkType', 'linkStyle']
  })
  const pois = usePois()

  return <div data-testid='ResourceEcosystem' className='h-full w-full p-1'>
    <div className='h-0.7/10 w-full pl-2 pt-1'>
      <FormHeader title={t('resource:ecosystem.title')} />
    </div>
    {links.data == null || pois.data == null
      ? <div className='h-9.3/10 w-full flex justify-center items-center'>
        <LocalLoader message={t('common:loading.dataLoading')} type='contained' />
      </div>
      : <div className='h-9.3/10 w-full p-1 pt-2'>
        <PoiEcosystem poi={poi} pois={pois.data} links={links.data} />
      </div>
    }
  </div>
}

export default ResourceEcosystem