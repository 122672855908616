// React libs
import { useState, useEffect, useCallback } from 'react';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
// Types
import * as CoreTypes from '../Models/Core.type';
// Common
import CoreCommon from '../../Resources/Common';

export interface IGetGenericData<t>
  extends CoreTypes.IHook<t[]> {
  refresh: Function
  refreshTime: number
}

export interface IGetGenericSingleData<t>
  extends CoreTypes.IHook<t> {
  refresh: Function
  refreshTime: number
}

const useGenericData = (getData: () => Promise<any>): IGetGenericSingleData<any> => {
  // Variables
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation(['common']);

  // State
  const [data, setData] = useState<any>();
  const [isLoading, setIsLoading]: [boolean, Function] = useState(false);
  const [needRefresh, setNeedRefresh] = useState<boolean>(true)
  const [refreshTime, setRefreshTime] = useState<number>(Date.now())

  // Actions
  const refresh = useCallback(() => {
    setNeedRefresh(true)
  }, [])

  // Effects
  useEffect(() => {
    if (needRefresh && !isLoading) {
      setIsLoading(true);
      getData().then((data: any) => {
        setData(data.data);
        setRefreshTime(Date.now())
      })
        .catch((e: CoreTypes.IWsException) => {
          enqueueSnackbar(
            e?.error?.message || t('common:errors.defaultMessage'),
            {
              ...CoreCommon.Constantes.snackbarDefaultProps,
              variant: 'error',
            }
          );
        }).finally(() => {
          setNeedRefresh(false)
          setIsLoading(false)
        })
    }
  }, [enqueueSnackbar, getData, isLoading, needRefresh, t]);

  return { data, isLoading, refresh, refreshTime };
};

export default useGenericData;
